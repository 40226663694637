@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add this CSS to your index.css file */
.table-container {
  max-height: 600px; /* Adjust height as needed */
  overflow-y: auto;  /* Enable vertical scrolling */
}

.table-container table {
  border-collapse: separate; /* Ensure borders are separate */
  border-spacing: 10px; /* Adjust spacing between columns */
  width: 90%;
}

.table-container th, .table-container td {
  padding: 10px; /* Add padding for better spacing */
  text-align: right;
}

.table-container tr {
  border-bottom: 1px solid #ccc; /* Full line between rows */
}

.table-container tr:last-child {
  border-bottom: none; /* Remove the last row's bottom border */
}

.table-container th {
  border-right: 1px solid #ccc; /* Add right border for each column header */
}

/* Remove right border for the last column */
.table-container td:last-child, .table-container th:last-child {
  border-right: none; 
}

/* AG-Grid Custom Styles */
.ag-theme-alpine {
  --ag-odd-row-background-color: #f8f8f8;
  --ag-header-background-color: #f0f0f0;
  --ag-header-foreground-color: #000;
}

.ag-theme-alpine .ag-row-hover {
  background-color: #e6f7ff;
}

.ag-theme-alpine .ag-cell-green {
  background-color: #eff5f1;
  color: #137333;
}

.ag-theme-alpine .ag-cell-red {
  background-color: #fff6f5;
  color: #A50E0E;
}

.ag-theme-alpine .ag-header-compact {
  padding: 0 4px !important;
}